.text-block{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  padding-top:2.4vw;
  padding-bottom:2.4vw;
  margin-bottom:-1px;

  &.background-primary {
    &.theme-1 {
      background:#2A38F8;
    }
    &.theme-2 {
      background:#FF3D00;
    }
    &.theme-3 {
      background:#DA8BFF;
    }
    &.theme-4 {
      background:#5900CC;
    }
    &.theme-5{
      background:#FF0;
    }
    &.theme-6{
      background:#05FF00;
    }
    &.theme-7{
      background:#00F0FF;
    } 
  }

  &.background-secondary {
    &.theme-1 {
      background:#05FF00;
      .texts {
        .text {
          .image-cta {
            color:#FF0;
            path {
              stroke:#FF0 !important;
            }
          }
        }
      }
    }
    &.theme-2 {
      background:#FF0;
      .texts {
        .text {
          .image-cta {
            color:#2A38F8;
            path {
              stroke:#2A38F8 !important;
            }
          }
        }
      }
    }
    &.theme-3 {
      background:#2A38F8;
      .texts {
        .text {
          .image-cta {
            color:#FF3D00;
            path {
              stroke:#FF3D00 !important;
            }
          }
        }
      }
    }
    &.theme-4 {
      background:#00F0FF;
      .texts {
        .text {
          .image-cta {
            color:#2A38F8;
            path {
              stroke:#2A38F8 !important;
            }
          }
        }
      }

    }
    &.theme-5{
      background:#FF003D;
      .texts {
        .text {
          .image-cta {
            color:#05FF00;
            path {
              stroke:#05FF00 !important;
            }
          }
        }
      }
    }
    &.theme-6{
      background:#5900CC;
      .texts {
        .text {
          .image-cta {
            color:#00F0FF;
            path {
              stroke:#00F0FF !important;
            }
          }
        }
      }
      
    }
    &.theme-7{
      background:#2A38F8;
      .texts {
        .text {
          .image-cta {
            color:#FF0;
            path {
              stroke:#FF0 !important;
            }
          }
        }
      }
    } 
  }

  .texts {
    width:calc(100% - 48px);
    margin-left:24px;
    position: relative;

    .text {
      font-size:5.555vw;
      color:#0E0E0E;
      font-feature-settings: 'ss02' on;
      line-height: 5.5vw;
      letter-spacing: -.05vw;
      margin-bottom: 2.4vw;
      text-indent: 8.4vw;
      position: relative;

      &.margin-top-1 {
        margin-top:8.4vw;
      }

      &.margin-bottom-1 {
        margin-bottom:8.4vw;
      }
      
      &.margin-left-1 {
        width:calc(100% - 16vw);
        margin-left:16vw;
      }

      &.margin-left-2 {
        width:calc(100% - 8.4vw);
        margin-left:8.4vw;
      }

      .small-text {
        position: absolute;
        left:0; 
        top:0;
        font-size:1vw;
        line-height: 100%;;
        width:8vw;
        text-indent: 0;
        margin-top:1.2vw;
        margin-left:-10vw;;

        .color-cta  { 
          text-transform: uppercase;
          margin-left:.1vw;
          &.theme-1 {
            color:#05FF00;
          }
          &.theme-2 {
            color:#FF0;
          }
          &.theme-3 {
            color:#2A38F8;
          }
          &.theme-4 {
            color:#00F0FF;
          }
          &.theme-5{
            color:#FF003D;
          }
          &.theme-6{
            color:#5900CC;
          }
          &.theme-7{
            color:#2A38F8;
          } 
        }
      }

      .image-cta {

        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 6.5vw;
        bottom:-1vw;
        margin-top:-1vw;
        margin-left:-.8vw;
        margin-right:-.8vw;
        width:12vw;

        &.small {
          height: 4.0vw;
          margin-top: 0;
          bottom:0;
        }
        
        // height:6.5vw;
        // margin-bottom:-1vw;
        // height:5.83vw;
        // width:17.048vw;
        &.theme-1 {
          color:#05FF00;
        }
        &.theme-2 {
          color:#FF0;
        }
        &.theme-3 {
          color:#2A38F8;
        }
        &.theme-4 {
          color:#00F0FF;
        }
        &.theme-5{
          color:#FF003D;
        }
        &.theme-6{
          color:#5900CC;
        }
        &.theme-7{
          color:#2A38F8;
        } 

        svg {
          width:100%;
          height: 100%;
          z-index: 0;
          position: relative;
          
        }

        .lf-player-container{
          position: absolute;
          width:100%;
          height: 100%;
          z-index: 1;
          padding:0;
          margin: 0;
          
          svg {
            width:100%;
            height: 100%;
            z-index: 0;
            padding:0;
            margin:0;
            left:0;
            position: absolute;
            top:0;
            scale:1.7;
          }
          
        }
        
       
        // cursor: pointer;
      }

      .text-cta {
        &.theme-1 {
          color:#05FF00;
        }
        &.theme-2 {
          color:#FF0;
        }
        &.theme-3 {
          color:#2A38F8;
        }
        &.theme-4 {
          color:#00F0FF;
        }
        &.theme-5{
          color:#FF003D;
        }
        &.theme-6{
          color:#5900CC;
        }
        &.theme-7{
          color:#2A38F8;
        } 
        text-wrap: nowrap;
        cursor: pointer;
      }

      

      .ticker {
        display:inline-block;
        margin:0;
        padding:0;
        border-radius: 5.4vw;
        overflow:hidden;
        width:32.4vw;
        height: 5.4vw;
        white-space:nowrap;
       
        text-indent:0;
        padding-left: 2.4vw;
        line-height: 5.5vw;
        padding-bottom:.3vw;
        padding-top:.3vw;
        margin-bottom:-.8vw;
        margin-top:-1.3vw;
       
        .ticker-block {
          font-size:4.8vw;
          line-height: 5.5vw;
          display: flex;
          align-items: center;
          justify-content: center;
         margin-top:0vw;
          
          span {
            display: inline-block;
            margin:0;
            padding:0;
            font-size:4.2vw;
            line-height: 5.5vw;
            margin-right:1.6vw;
            margin-left:1.6vw;
            

            &.style-1 {
              font-family:  "Victor Narrow Regular Italic";
            }
          }
        }
        
        &.theme-1 {
          background:#2A38F8;
        }
        &.theme-2 {
          background:#FF3D00;
        }
        &.theme-3 {
          background:#DA8BFF;
        }
        &.theme-4 {
          background:#5900CC;
        }
        &.theme-5{
          background:#FF0;
        }
        &.theme-6{
          background:#05FF00;
        }
        &.theme-7{
          background:#00F0FF;
        } 
       
      }
    }
  }

  

  .media-player-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    visibility: 0;
    z-index: 0;
    position: fixed;
    top:0; 
    left:0;
    width:100%;
    height:100%;

    .close-media-player-button {
      position: absolute;
      top:0;
      right:0;
      cursor: pointer;
      background:black;
      color:white;
      pointer-events: all;
    }

  }

  .media-player-modal-mobile {
    display: none;
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {

    .media-player-modal-mobile {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      position: relative;
      margin-top:8.4vw;
    }


    .texts {
      width:calc(100% - 8.4vw);
      margin-left:4.2vw;
      position: relative;
  
      .text {
        font-size:8.533vw;
        color:#0E0E0E;
        font-weight: 400;
        font-feature-settings: 'ss02' on;
        line-height:112.5%;
        letter-spacing: -.08533vw;
        margin-bottom: 4.8vw;
        text-indent: 6.2vw;
        position: relative;
        
        .text-cta {
          pointer-events: none;
        }

        &.margin-top-1 {
          margin-top:8.4vw;
        }
  
        &.margin-bottom-1 {
          margin-bottom:8.4vw;
        }
        
        &.margin-left-1{
          width:calc(100%);
          margin-left:0;
        }

        &.margin-left-1-mobile {
          width:calc(100% - 12vw);
          margin-left:12vw;
        }
  
        &.margin-left-2 {
          width:calc(100%);
          margin-left:0vw;
        }
  
        .small-text {
          display: none;
          position: absolute;
          left:0; 
          top:0;
          font-size:1vw;
          line-height: 100%;;
          width:8vw;
          text-indent: 0;
          margin-top:1.2vw;
          margin-left:-10vw;
  
          .color-cta  { 
            text-transform: uppercase;
            margin-left:.1vw;
            pointer-events: none;
          }
        }
  
        .image-cta {
          pointer-events: none;
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 10vw;
          bottom:-2vw;
          margin-top:-1vw;
          margin-left:.4vw;
          margin-right:.4vw;
          width:22vw;

          &.tight {
            width:18vw;
            margin-left:0vw;
            margin-right:0vw;
          }
  
          &.small {
            height: 8.4vw;
            margin-top: 0;
            bottom:0;
          }
          
          svg {
            width:100%;
            height: 100%;
            z-index: 0;
            position: relative;
            
          }
  
          .lf-player-container{
            position: absolute;
            width:100%;
            height: 100%;
            z-index: 1;
            padding:0;
            margin: 0;
            
            svg {
              width:100%;
              height: 100%;
              z-index: 0;
              padding:0;
              margin:0;
              left:0;
              position: absolute;
              top:0;
              scale:1.7;
            }
          } 
        }
  
        .ticker {
          display:inline-block;
          margin:0;
          padding:0;
          border-radius: 8.533vw;
          overflow:hidden;
          width:50.6vw;
          height:8.533vw;
          white-space:nowrap;
         
          text-indent:0;
          padding-left: 4.8vw;
          line-height: 100%;
          padding-bottom:1.6vw;
          padding-top:1.6vw;
          transform: translateZ(0);


         
          margin-bottom:-2.8vw;
          margin-top:0.0vw;
         
         
          .ticker-block {
            font-size:8.533vw;
            line-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateZ(0);
            // margin-top:.5vw;
            
            span {
              display: inline-block;
              margin:0;
              padding:0;
              font-size:8.533vw;
              line-height: 100%;
              margin-right:2.6vw;
              margin-left:2.6vw;
            }
          }
        }
      }
    }

  }


}


