.smiley-media-block{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0; 
  height: 48vw;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  padding-top:2.4vw;
  padding-bottom:2.4vw;
  margin-bottom:-1px;
  cursor: pointer;
  pointer-events: all;
  user-select: none;

  .smiley-media-canvas {
    width: 100%;
    height: 100%;
   
    canvas {
      will-change:opacity;
    }

    
  
  }

  table {
    will-change: opacity, transform;
    margin-left:-2.8vw !important;
    user-select: none;
  }


  &.background-primary {
    &.theme-1 {
      background:#2A38F8;
    }
    &.theme-2 {
      background:#FF3D00;
    }
    &.theme-3 {
      background:#DA8BFF;
    }
    &.theme-4 {
      background:#5900CC;
    }
    &.theme-5{
      background:#FF0;
    }
    &.theme-6{
      background:#05FF00;
    }
    &.theme-7{
      background:#00F0FF;
    } 
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
   
    width: 100%;
    height: 70vw;
    margin-left:-2.4vw;
  }


 
}


