.subscribe-block {
  display: flex;
  flex-direction: column;
  height: auto;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  padding-top:2.4vw;
  padding-bottom:2.4vw;
  font-size: 0;
  background: #83807C;
  // min-height: 10vw;
  padding-bottom:6.4vw;
  line-height: 0px;;

  .titles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
      width:calc(64vw);
  
      position: relative;
      font-weight:400;
      margin:0;
      padding:0;
      margin-left:24px;
      font-size:5.555vw;
      color:#0E0E0E;
      font-feature-settings: 'ss02' on;
      line-height: 100%;
      letter-spacing: -.05vw;
      margin-bottom: 2.4vw;
      text-indent: 8.4vw;
      margin-bottom:16vw;
  
      .image-cta {
        &.theme-1 {
          color:#05FF00;
          path {
            stroke:#05FF00 !important;
          }
        }
        &.theme-2 {
          color:#FF0;
          path {
            stroke:#FF0 !important;
          }
        }
        &.theme-3 {
          color:#2A38F8;
          path {
            stroke:#2A38F8 !important;
          }
        }
        &.theme-4 {
          color:#00F0FF;
          path {
            stroke:#00F0FF !important;
          }
        }
        &.theme-5{
          color:#FF003D;
          path {
            stroke:#FF003D !important;
          }
        }
        &.theme-6{
          color:#5900CC;
          path {
            stroke:#5900CC !important;
          }
        }
        &.theme-7{
          color:#2A38F8;
          path {
            stroke:#2A38F8 !important;
          }
        } 
       

        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height:3.9vw;
        width:auto;
        margin-bottom:0vw;

        svg {
          width:100%;
          height: 100%;
          z-index: 0;
          position: relative;
        }
        .lf-player-container{
          position: absolute;
          width:100%;
          height: 100%;
          z-index: 1;
          padding:0;
          margin: 0;
          

          svg {
            position: absolute;
            left:0;
            scale:1.7;
          }
        }

      }


     








  
    }
  
    .title2 {
      
      
      margin-top:6.5vw;
      position: absolute;
      right:24px;
      width:61.5vw;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }




  .subscribe-list-item {
    // width:100%;
    margin:0;
    padding:0;
    font-size: 0;
    font-weight: 400;
    display: flex;
    width: 100%;

    &.hide {
      display: none;
    }

   
    // flex-shrink:1;
  
    .subscribe-form {
      margin:0;
      padding:0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items:flex-start;
      width:calc(100% - 48px);
      margin-left:24px;
      gap:1vw;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .email {
      padding:0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      border: 1px solid #0E0E0E;
      color:#0E0E0E;
      border-radius: 4.2vw;
      width:calc(70vw - 96px);
      height: 4.3vw;
      overflow:hidden;
      cursor: pointer;
      font-size:2vw;
      line-height:2vw;
      font-weight: 400;
      padding-left:48px;
      padding-right:48px;
      text-align: center;
      background-color: transparent;

      &:focus::placeholder {
        color:transparent;
      }

      &::placeholder {
        color:#0e0e0e9f;
      }
      
    };
    
    .item {
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      border: 1px solid #0E0E0E;
      border-radius: 40px;
      width:70vw;
      height: 4.3vw;
      overflow:hidden;
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        width:calc(100% - 48px);
        margin-left:24px;
        font-size: 4.2vw;
        height: 8.4vw;

        input {
          font-size: 4.2vw;
        }
      }

      .subscribe-title {
        // margin-top:24px;
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items: center;
        padding:0;
        margin: 0;
        font-size:2vw;
        line-height:2vw;
        font-weight: 400;
        background:none;
        border:none;
        width:100%;
        height: 100%;
        text-align: center;
    

        @media only screen and (max-width: 767px) {
         
          font-size: 4.2vw;
          
        }
  
      } 

    }

    .subscribe-button {
      
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color:#0E0E0E;
      padding:0;
      margin: 0;
      font-size: 1.4vw;
      font-weight: 400;
      height: 4.3vw;
      flex-grow:1;
      border:none;
      width:13.9vw;
      cursor: pointer;
      text-transform: uppercase;
      color:#0E0E0E;
      transition: all .3s ease-out;

      &.theme-1 {
        background-color:#05FF00;
        &:hover {
          background-color:#0E0E0E;
          color:#05FF00;
        }
      }
      &.theme-2 {
        background-color:#FF0;
        &:hover {
          background-color:#0E0E0E;
          color:#FF0;
        }
      }
      &.theme-3 {
        background-color:#2A38F8;
        color: white;
        &:hover {
          background-color:white;
          color:#2A38F8;
        }
      }
      &.theme-4 {
        background-color:#00F0FF;
      }
      &.theme-5{
        background-color:#FF003D;
        &:hover {
          background-color:#0E0E0E;
          color:#FF003D;
        }
      }
      &.theme-6{
        background-color:#5900CC;
        color: white;
        &:hover {
          background-color:white;
          color:#5900CC;
        }
      }
      &.theme-7{
        background-color:#2A38F8;
        color:white;
        &:hover {
          background-color:white;
          color:#2A38F8;
        }
      } 


      @media only screen and (max-width: 767px) {
        width:100%;
        font-size: 4.2vw;
        height: 8.4vw;
      }
    }
    
  }
  
  .thanks {
    width:calc(100% - 48px);
    margin-left:24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #0E0E0E;
    border-radius: 40px;
    font-size:2vw;
    line-height:2vw;
    font-weight: 400;
    height: 4.3vw;
    display: none;
    &.show {
      display: flex;
    }

    @media only screen and (max-width: 767px) {
     
      font-size: 4.2vw;
      height: 8.4vw;

    }

  }


  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    .titles {
      .title {
        width:calc(100% - 8.4vw);
        position: relative;
        font-weight:400;
        margin:0;
        padding:0;
        margin-left:4.2vw;
        font-size:8.5333vw;
        color:#0E0E0E;
        font-feature-settings: 'ss02' on;
        line-height: 100%;
        letter-spacing: -.08533vw;
        text-indent: 8.4vw;
        margin-bottom:64vw;
    
        .image-cta {
          &.theme-1 {
            color:#05FF00;
            path {
              stroke:#05FF00 !important;
            }
          }
          &.theme-2 {
            color:#FF0;
            path {
              stroke:#FF0 !important;
            }
          }
          &.theme-3 {
            color:#2A38F8;
            path {
              stroke:#2A38F8 !important;
            }
          }
          &.theme-4 {
            color:#00F0FF;
            path {
              stroke:#00F0FF !important;
            }
          }
          &.theme-5{
            color:#FF003D;
            path {
              stroke:#FF003D !important;
            }
          }
          &.theme-6{
            color:#5900CC;
            path {
              stroke:#5900CC !important;
            }
          }
          &.theme-7{
            color:#2A38F8;
            path {
              stroke:#2A38F8 !important;
            }
          } 
         
  
          position: absolute;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height:18vw;
          width:auto;
          margin-top:2.4vw;
          
  
          svg {
            width:100%;
            height: 100%;
            z-index: 0;
            position: relative;
          }
          .lf-player-container{
            position: absolute;
            width:100%;
            height: 100%;
            z-index: 1;
            padding:0;
            margin: 0;
            overflow:hidden;
  
            svg {
              position: absolute;
              left:0;
              scale:1.7;
            }
          }
  
        }
      }
    
      .title2 {
        
        margin-top:48vw;
        position: absolute;
        right:4.2vw;
        width:calc(100% - 8.4vw);
        svg {
          width: 100%;
          height: auto;
        }
      }
    }

    .subscribe-list-item {
      .subscribe-form {
      
        margin:0;
        padding:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        width:calc(100% - 8.4vw);
        margin-left:4.2vw;
        gap:4.2vw;

        margin-bottom: 4.2vw;
        margin-top: 4.2vw;
      }

      .email {
        padding-left:4.2vw;
        padding-right: 4.2vw;
        border-radius: 11vw;
        width:calc(100% - 16.8vw);
        input {
          font-size: 4.2vw;
        }
        margin-left:4.2vw;
        font-size: 4.2vw;
        height: 11vw;
  

  
      }

      .subscribe-button {
      
        border-radius: 11vw;
        font-size: 4.2vw;
        height: 11vw;
        width:calc(100% - 8.4vw);
        margin-left:4.2vw;
        
      }
    }

    .thanks {
      width:calc(100% - 8.4vw);
      margin-left:4.2vw;
      border-radius: 11vw;
      font-size:4.2vw;
      line-height:100%;
      font-weight: 400;
      height: 8.4vw;
      display: none;
      margin-bottom: 4.2vw;
      margin-top: 4.2vw;
      &.show {
        display: flex;
      }
  
    }
  
  }


 
}


