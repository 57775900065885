.floating-logo{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 3.9vw;
  border-radius: 3.9vw;
  width:6.5vw;
  overflow:hidden;
  padding:0;
  margin:0;
  position: fixed;
  bottom:24px;
  left:24px;
  z-index: 1000;
  cursor: pointer;
  border: 1px solid #0E0E0E;

  &.theme-1 {
    background-color:#2A38F8;
  }
  
  &.theme-2 {
    background-color:#FF3D00;
  }
  
  &.theme-3 {
    background-color:#DA8BFF;
  }
  
  &.theme-4 {
    background-color:#5900CC;
  }
  
  &.theme-5 {
    background-color:#FFFF00;
  }
  
  &.theme-6 {
    background-color:#05FF00;
  }
  
  &.theme-7 {
    background-color:#2A38F8;
  }


  .lottie{
    height: 4vw;
    width: 4vw;
    flex-shrink: 0;
    margin-left: 1.2vw;
    margin-right: 1.0vw;
  }

  span {
    margin:0;
    padding:0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left:.7vw;
    height: 1.7vw;
    line-height: 100%;
    font-size: 1.0vw;
  }

  a {
    text-decoration: none;
    color:#0E0E0E;
    cursor: pointer;
  }

  transition: .3s ease-out width;
  
  // width:13.9vw;

  &:hover {
    width:14.1vw;
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    
    height: 8.4vw;
    border-radius: 5.3vw;
    width:13.6vw;
    bottom:4.2vw;
    left:4.2vw;
    z-index: 1000;
    border: 1px solid #0E0E0E;

    &:hover {
      width:32vw;
    }

    .lottie{
      height: 8.4vw;
      width: 8.4vw;
      flex-shrink: 0;
      margin-left: 2.4vw;
      margin-right: 3.6vw;
    }
  
    span {
      margin-left:1.5vw;
      height: 3.6vw;
      line-height: 100%;
      font-size: 2.4vw;
    }

  }
 
}
