@font-face {
  font-family: "StabilGrotesk";
  src: url("./fonts/StabilGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/StabilGrotesk-Regular.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "StabilGrotesk";
  src: url("./fonts/StabilGrotesk-Bold.woff2") format("woff2"),
    url("./fonts/StabilGrotesk-Bold.woff") format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "StabilGrotesk";
  src: url("./fonts/StabilGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/StabilGrotesk-Medium.woff") format("woff");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "StabilGrotesk";
  src: url("./fonts/StabilGrotesk-Light.woff2") format("woff2"),
    url("./fonts/StabilGrotesk-Light.woff") format("woff");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: "StabilGrotesk";
  src: url("./fonts/StabilGrotesk-BlackItalic.woff2") format("woff2"),
    url("./fonts/StabilGrotesk-BlackItalic.woff") format("woff");
  font-display: swap;
  font-weight: 800;
  font-style: italic;
}


@font-face {
  font-family: "Victor Narrow Regular Italic";
  src: url("./fonts/VictorNarrow-RegularItalic.woff2") format("woff2"),
    url("./fonts/VictorNarrow-RegularItalic.woff") format("woff");
}



/* Global Styles */

body,html {
  width:100%;
  height: 100%;
  // overflow-y:scroll;
  margin:0;
  padding:0;
  font-family: "StabilGrotesk", sans-serif;
  color:  #0E0E0E;
  background-color: #F4E9E1;
  font-feature-settings: 'ss02' on;
  transition:background-color .5s ease-in-out;
  
  
}

body {
  width:100%;
  overflow:hidden;
  overflow-y:scroll;
  overscroll-behavior-y: none;

  // position: absolute;
}

button, a, p,span, div, h1, h2, h3, h4, h5, h6 {
  font-family: "StabilGrotesk", sans-serif;
  font-feature-settings: 'ss02' on;
}

#root{
  height: 100%;
  width: 100%;
  max-width: 100vw;
  margin:0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}



/* global colors */

.sandBackground { background-color: #F4E9E1; }