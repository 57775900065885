.different-media-block{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  padding-top:4.8vw;
  padding-bottom:4.8vw;
  box-sizing: border-box;
  line-height: 0;
  margin-bottom:-1px;

  &.background-primary {
    &.theme-1 {
      background:#2A38F8;
      color:#05FF00;
      svg {
        path {
          fill:#05FF00;
        }
      }
    }
    &.theme-2 {
      background:#FF3D00;
      color:#FF0;
      svg {
        path {
          fill:#FF0;
        }
      }
    }
    &.theme-3 {
      background:#DA8BFF;
      color:#2A38F8;
      svg {
        path {
          fill:#2A38F8;
        }
      }
    }
    &.theme-4 {
      background:#5900CC;
      color:#00F0FF;
      
      
      
    }
    &.theme-5{
      background:#FF0;
      color:#FF003D;
      svg {
        path {
          fill:#FF003D;
        }
      }
    }
    &.theme-6{
      background:#05FF00;
      color:#5900CC;
      svg {
        path {
          fill:#5900CC;
        }
      }

    }
    &.theme-7{
      background:#00F0FF;
      color:#2A38F8;
      svg {
        path {
          fill:#2A38F8;
        }
      }
    } 
  }

  

  &.background-secondary {
    &.theme-1 {
      background:#05FF00;
      #different, .different-bottom-text {
        color:#FF0;
      }
      .lf-player-container {
        svg {
          path {
            fill: #2A38F8;
          }
        }
      }
      
    }
    &.theme-2 {
      background:#FF0;
      #different, .different-bottom-text {
        color:#2A38F8;
      }
      .lf-player-container {
        svg {
          path {
            fill: #FF3D00;
          }
        }
      }
    }
    &.theme-3 {
      background:#2A38F8;
      #different, .different-bottom-text {
        color:#FF003D;
      }
      .lf-player-container {
        svg {
          path {
            fill: #DA8BFF;
          }
        }
      }
    }
    &.theme-4 {
      background:#00F0FF;
      #different, .different-bottom-text {
        color:#2A38F8;
      }
      .lf-player-container {
        svg {
          path {
            fill: #5900CC;
          }
        }
      }
    }
    &.theme-5{
      background:#FF003D;
      #different, .different-bottom-text {
        color:#05FF00;
      }
      .lf-player-container {
        svg {
          path {
            fill: #FF0;
          }
        }
      }
    }
    &.theme-6{
      background:#5900CC;
      #different, .different-bottom-text {
        color:#00F0FF;
      }
      .lf-player-container {
        svg {
          path {
            fill: #05FF00;
          }
        }
      }
      
    }
    &.theme-7{
      background:#2A38F8;
      #different, .different-bottom-text {
        color:#FF0;
      }
      .lf-player-container {
        svg {
          path {
            fill: #FF0;
          }
        }
      }
    } 
  }

  .media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    position: relative;
    overflow-x:hidden;

    .player {
      margin:0;
      padding:0;
      display: flex;
      margin-top:8.4vw;
      margin-bottom:8.4vw;
      width:calc(103.4vw - 48px );
      position: relative;
    }

    .player-mobile {
      display: none;
      margin-top:8.4vw;
      margin-bottom:8.4vw;
      width:100%;
      margin:0;
      padding:0;
    }

    .lf-player-container {
      position: relative;
      width:inherit;
      margin:0;
      padding:0;
    }


    .video-wrapper {
      margin-top:-8.4vw;
      position: absolute;
      width:100%;
      height: 100%;
    }

    .heads {
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;
      height: auto;
      position: absolute;
      margin-top:-8vw;
      .head {
        height: 56vw;
        display: flex;
        position: absolute;
        opacity: 0;
        visibility: 1;
        filter:drop-shadow(5px 5px 15px);
      }
    }

    .text-layer {
      width:100%;
      height:auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      flex-direction: column;


      #different {
        // position: absolute;
        // height:20vh;
        width:88.95vw;
        // margin-bottom:2vh;
       
        // svg {
        //   width:86vw;
        // }
      }

      .different-symbol {
        // position: absolute;
        width:88.95vw;
        
        
      }

      .different-bottom-text {
        // position: absolute;
        font-size:9vw;
        font-family: "StabilGrotesk";
        font-style: italic;
        font-weight: 800;
        letter-spacing: -.0919vw;
        text-transform: uppercase;
        z-index: 100;
        
      }

    }
   

    .rtn-ascii {
      position: absolute;
 
    }

    .lottie {
      position: absolute;
    }

    .row {
      display: flex;
      width:100%;
      height: auto;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
  
      .ascii { 
        height: 1.4vw;
        margin-bottom:.26vw;
        
        &.gap { 
          padding-right:10vw;
        }
    
        &.flip {
          transform: scale(-1);
          padding-left:.2vw;
        }
    
      }
    }
    
    
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    padding-top:4.8vw;
    padding-bottom:8.4vw;


    .media {
      
      height: auto;
      overflow:hidden;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      position: relative;

      .player {
        display: none;
        width:100%;
        
      }
  
      .player-mobile {
        display: flex;
        margin-top:8.4vw;
        margin-bottom:8.4vw;
        width: calc(100% - 6.4vw);
        margin:0;
        padding:0;
        
      }

      .lf-player-container {
        position: relative;
        width:100%;
        margin:0;
        padding:0;
      }


      .heads {
        width:auto;
        overflow:hidden;
        margin-top:-40vw;
        height: 140vw;
        z-index: 100;
        .head {
          height: 140vw;
          overflow-x:hidden;
        }
      }
  
      .text-layer {
        width:100%;
        height:auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        flex-direction: column;
  
        overflow-x:hidden;
        #different {
          // position: absolute;
          // height:20vh;
          width:110vw;
          margin-top:8.4vw;
          // margin-bottom:2vh;
         
          // svg {
          //   width:86vw;
          // }
        }
  
        .different-symbol {
          // position: absolute;
          width:140vw;
          margin-top:4.8vw;
          margin-bottom:-4.8vw;
        }
  
        .different-bottom-text {
          // position: absolute;
          width:120vw;
          font-size:18vw;
          line-height: 88%;
          text-align: center;
          font-family: "StabilGrotesk";
          font-style: italic;
          font-weight: 800;
          letter-spacing: -.0919vw;
          text-transform: uppercase;
          
        }
  
      }
     
  
     
  
      .lottie {
        position: absolute;
      }
  
      .row {
       
        .ascii { 
          height: 2.1vw;
          margin-bottom:.26vw;
          
          &.gap { 
            padding-right:10vw;
          }
      
          &.flip {
            transform: scale(-1);
            padding-left:.2vw;
          }
      
        }
      }
      
      
    }


  }


 
}


