.content{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width:100%;
  padding:0;
  margin:0;
  position: relative;
  // position: absolute;
  // overflow-x:hidden;
  // overflow-y:auto;
  // z-index: 1;
  font-size:0;

  section {
    position: relative;
    width: 100%;
    height: auto;

     /* theme specific colors */
  &.section-1{
    background: #F4E9E1;
  }

  &.section-2, &.section-4{
    &.theme-1 {
      background:#2A38F8;
      .title {
        color:#05FF00;
      }
    }
    
    &.theme-2 {
      background:#FF3D00;
      .title {
        color:#FFFF00;
      }
    }
    
    &.theme-3 {
      background:#DA8BFF;
      .title{
        color:#2A38F8;
      }
    }
    
    &.theme-4 {
      background:#5900CC;
      .title {
        color:#00F0FF;
      }
      .act, .numeral, .logo {
        color:#F4E9E1;
      }
    }
    
    &.theme-5 {
      background:#FFFF00;
      .title {
        color:#FF003D;
      }
    }
    
    &.theme-6 {
      background:#05FF00;
      .title {
        color:#5900CC;
      }
    }
    
    &.theme-7 {
      background:#00F0FF;
      .title {
        color:#2A38F8;
      }
    }
  }


  &.section-3{
    &.theme-1 {
      background:#05FF00;
      .title {
        color:#2A38F8;
      }
    }
    
    &.theme-2 {
      background:#FFFF00;
      .title {
        color:#FF3D00;
      }
    }
    
    &.theme-3 {
      background:#2A38F8;
      .title{
        color:#DA8BFF;
      }
    }
    
    &.theme-4 {
      background:#00F0FF;
      .title {
        color:#5900CC;
      }
    }
    
    &.theme-5 {
      background:#FF003D;
      .title {
        color:#FFFF00;
      }
    }
    
    &.theme-6 {
      background:#5900CC;
      .title {
        color:#05FF00;
      }
    }
    
    &.theme-7 {
      background:#2A38F8;
      .title {
        color:#00F0FF;
      }
    }
  }

  &.section-5 {
    background: #83807C;
    .title {
      color:#F4E9E1;
    }
  }


  }
  

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
   
  }


 
}


