.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  padding-top:2.4vw;
  padding-bottom:2.4vw;
  background: #83807C;
  // min-height: 10vw;
  // padding-bottom:8.4vw;


  &.theme-1 {
    .footer-content-left {
      >svg {
        color:#05FF00;
      }
    }
  }
  &.theme-2 {
    .footer-content-left {
      >svg {
        color:#ffff00;
      }
    }
  }
  &.theme-3 {
    .footer-content-left {
      >svg {
        color:#2A38F8;
      }
    }
  }
  &.theme-4 {
    .footer-content-left {
      >svg {
        color:#00F0FF;
      }
    }
  }
  &.theme-5{
    .footer-content-left {
      >svg {
        color:#FF003D;
      }
    }
  }
  &.theme-6{
    .footer-content-left {
      >svg {
        color:#5900CC;
      }
    }
  }
  &.theme-7{
    .footer-content-left {
      >svg {
        color:#2A38F8;
      }
    }
  } 

  .divider {
    background-color:#0E0E0E;
    height: 1px;
    width:calc(100% - 48px);
    margin-bottom:4.8vw;
  }

  .footer-content{

    width:calc(100% - 48px);
    margin-bottom:4.8vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .footer-content-left {
      display: flex;
      flex-direction: row;
      svg {
      
        width: 7vw;
        
      }
      .footer-titles{
        margin-left:1.66vw;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        .footer-title{
          margin:0;
          padding:0;
          color:#F4E9E1;
          font-family: "Victor Narrow Regular Italic";
          font-size: 1.666vw;
          font-style: italic;
          font-weight: 100;
          line-height:100%;
          letter-spacing: -0.01666vw;

        }

        .footer-subtitle{
          margin:0;
          padding:0;
          font-size: .83vw;
          font-weight: 400;
          line-height:100%;
        }
      }
    }


    .footer-content-right {
      display: flex;
      flex-direction: row;
      gap:4.8vw;
      align-items: flex-start;

      .social-links {
        display: flex;
        flex-direction: column;

        .social-link { 
          margin:0;
          padding:0;
          font-size: .83vw;
          font-weight: 400;
          line-height:100%;
          color:#0E0E0E;
          text-decoration: underline;
          margin-bottom:.4vw;
        }
      }

     

      .copyright {
        margin:0;
        padding:0;
        font-size: .83vw;
        font-weight: 400;
        line-height:100%;
        color:#0E0E0E;
      }
    }
  }


  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    padding-bottom:28vw;
    .divider {
     
      width:calc(100% - 8.4vw);
      margin-bottom:8.4vw;
    }
  
   
    .footer-content{
  
      width:calc(100% - 8.4vw);
      margin-bottom:8.4vw;

      .footer-content-left {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        svg {
          
          width: 26.5vw;
          
        }



        .footer-titles{
          margin-left:6.4vw;
          flex-shrink: 0;
          margin-top:-.8vw;
          .footer-title{
            font-size: 6.4vw;
            line-height: 100%;
            font-style: italic;
            font-weight: 100;
            letter-spacing: -0.064vw;
          }
  
          .footer-subtitle{
            margin-top:.8vw;
            font-size: 3.2vw;

          }
        }
      }
  
  
      .footer-content-right {
        display: flex;
        flex-direction: row;
        width:calc(100% - 8.4vw);
        justify-content: space-between;
        gap:4.8vw;
        // align-items: flex-end;
        position: absolute;
        top:32vw;

        .social-link { 
          margin:0;
          padding:0;
          font-size: 3.2vw;
          font-weight: 400;
          line-height:100%;
          color:#0E0E0E;
          text-decoration: underline;
        }
  
        .copyright {
          margin:0;
          padding:0;
          font-size: 3.2vw;
          font-weight: 400;
          line-height:100%;
          color:#0E0E0E;
          margin-left:18vw;
        }
      }
    }
  
  }


 
}


