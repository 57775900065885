.media-block{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  height: auto;

  .media-blocks {
    top: 0px; 
    left: 0px; 
    position: relative;
    width: 100%; 
    height: 100%;

    .media-block{
      position: relative;
      width: 100%;
      height: 110vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .background {
        width:100%;
        height: 110vh;
        object-fit: cover;  
      }

      .media-card {
        position: absolute;
      }
  
      .media-card-inner {
        display: block;
      }

      .media-card-inner-mobile {
        display: none;
      }

    
      &.media-block-1 {
        .background {
          background: #5900CC;
        }

        .media-card {
          background: #FF0;
          svg {
            color:#FF0;
            width:68vw;
          }
        }
      }

      &.media-block-2 {
        .background {
          background:#05FF00;
        }
        
        .media-card {
          margin-left:20vw;
          background:#FF003D;
          svg {
            color:#FF003D;
            width:33vw;
          }
        }
      }

      &.media-block-3 {
        .background {
          background:#2A38F8;
        }

        .media-card {
          margin-left:6vw;
          background:#DA8BFF;
          svg {
            color:#DA8BFF;
            width:69vw;
          }
        }
      }

      &.media-block-4 {
        .background {
          background:#FF0;
        }

        .media-card {
          margin-left:-10vw;
          background:#2A38F8;
          
          svg {
            color:#2A38F8;
            width:70vw;
          }
        }
      }
    }
  }
    

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    .media-blocks {
   
  
      .media-block{
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
  
        .background {
          width:100%;
          height: 100vh;
          object-fit: cover;  
        }
  
        .media-card {
          position: absolute;
        }
    
        .media-card-inner {
          display: none;
        }
  
        .media-card-inner-mobile {
          display: block;
        }
  
      
        &.media-block-1 {
          .media-card {
            svg {
              width:68vw;
            }
          }
        }
  
        &.media-block-2 {
          .media-card {
            margin-left: 6vw;
            svg {
              width:68vw;
            }
          }
        }
  
        &.media-block-3 {
          .media-card {
            svg {
              width:68vw;
            }
          }
        }

        &.media-block-4 {
          .media-card {
            svg {
              width:68vw;
            }
          }
        }
      }
    }
  }


 

}


