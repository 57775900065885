.title-block{
  display: flex;
  height: auto;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  padding-top:6.4vw;
  padding-bottom:2.4vw;
  margin-bottom:-1px;
  
  .titles {
    width:calc(100% - 48px);
    margin-left:24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 8.4vw;

    .title {
      margin:0;
      padding:0;
      font-weight: 400;
      font-size:8.333vw;
      color:#0E0E0E;
      font-feature-settings: 'ss02' on;
      line-height: 80%;
      letter-spacing: -.05vw;
      position: relative;
      text-transform: uppercase;
      



      &.margin-top-1 {
        margin-top:8.4vw;
      }
      
      &.margin-left-1 {
        width:calc(100% - 16vw);
        margin-left:16vw;
      }

      &.margin-left-2 {
        width:calc(100% - 8.4vw);
        margin-left:8.4vw;
      }

      &.margin-right-1 {
        //width:calc(100% - 8.4vw);
        //display: flex;
        margin-right:8.4vw;
        align-self: flex-end;
        display: flex;
      }

      .image-cta {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height:5.83vw;
        width:17.048vw;


        &.theme-1 {
          color:#05FF00;
          path {
            stroke:#05FF00 !important;
          }
        }
        &.theme-2 {
          color:#FF0;
          path {
            stroke:#FF0 !important;
          }
        }
        &.theme-3 {
          color:#2A38F8;
          path {
            stroke:#2A38F8 !important;
          }
        }
        &.theme-4 {
          color:#00F0FF;
          path {
            stroke:#00F0FF !important;
          }
        }
        &.theme-5{
          color:#FF003D;
          path {
            stroke:#FF003D !important;
          }
        }
        &.theme-6{
          color:#5900CC;
          path {
            stroke:#5900CC !important;
          }
        }
        &.theme-7{
          color:#2A38F8;
          path {
            stroke:#2A38F8 !important;
          }
        } 
        svg {
          width:100%;
          height: 100%;
          z-index: 0;
          position: relative;
        }
        .lf-player-container{
          position: absolute;
          width:100%;
          height: 100%;
          z-index: 1;
          padding:0;
          margin: 0;
          margin-top:-25%;

          svg {
            scale:.95;
          }
        }
 
        // cursor: pointer;
      }

      .text-cta {
        &.theme-1 {
          color:#05FF00;
        }
        &.theme-2 {
          color:#FF0;
        }
        &.theme-3 {
          color:#2A38F8;
        }
        &.theme-4 {
          color:#00F0FF;
        }
        &.theme-5{
          color:#FF003D;
        }
        &.theme-6{
          color:#5900CC;
        }
        &.theme-7{
          color:#2A38F8;
        } 
        text-wrap: nowrap;
        cursor: pointer;
      }

      
    }
  }
 

  &.background-primary {
    &.theme-1 {
      background:#2A38F8;
    }
    &.theme-2 {
      background:#FF3D00;
    }
    &.theme-3 {
      background:#DA8BFF;
    }
    &.theme-4 {
      background:#5900CC;
    }
    &.theme-5{
      background:#FF0;
    }
    &.theme-6{
      background:#05FF00;
    }
    &.theme-7{
      background:#00F0FF;
    } 
  }

  &.background-secondary {
    &.theme-1 {
      background:#05FF00;
    }
    &.theme-2 {
      background:#FF0;
    }
    &.theme-3 {
      background:#2A38F8;
    }
    &.theme-4 {
      background:#00F0FF;
    }
    &.theme-5{
      background:#FF003D;
    }
    &.theme-6{
      background:#5900CC;
    }
    &.theme-7{
      background:#2A38F8;
    } 
  }



  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    .titles {
      width:calc(100% - 8.4vw);
      margin-left:4.2vw;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 8.4vw;
  
      .title {
        margin:0;
        padding:0;
        font-weight: 400;
        font-size:26vw;
        color:#0E0E0E;
        font-feature-settings: 'ss02' on;
        line-height: 80%;
        letter-spacing: -.05vw;
        position: relative;
        text-transform: uppercase;
        
  
        &.margin-top-1 {
          margin-top:8.4vw;
        }
        
        &.margin-left-1 {
          width:calc(100%);
          margin-left:0vw;
        }
  
        &.margin-left-2 {
          width:calc(100%);
          margin-left:0;
        }
  
        &.margin-right-1 {
          //width:calc(100% - 8.4vw);
          //display: flex;
          margin-right:0vw;
          align-self: unset;
          display: flex;
        }
  
        .image-cta {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height:17.33vw;
          width:50vw;
  
  
         
   
          // cursor: pointer;
        }
  
        
        
      }
    }
   
  }


 
}


