.rtn-block{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  padding-top:8.4vw;
  padding-bottom:8.4vw;
  box-sizing: border-box;
  line-height: 0;
  margin-bottom:-1px;

  &.background-primary {
    &.theme-1 {
      background:#2A38F8;
      color:#05FF00;
    }
    &.theme-2 {
      background:#FF3D00;
      color:#FF0;
    }
    &.theme-3 {
      background:#DA8BFF;
      color:#2A38F8;
    }
    &.theme-4 {
      background:#5900CC;
      color:#00F0FF;
    }
    &.theme-5{
      background:#FF0;
      color:#FF003D;
    }
    &.theme-6{
      background:#05FF00;
      color:#5900CC;

    }
    &.theme-7{
      background:#00F0FF;
      color:#2A38F8;
    } 
  }

  &.background-secondary {
    &.theme-1 {
      background:#05FF00;
    }
    &.theme-2 {
      background:#FF0;
    }
    &.theme-3 {
      background:#2A38F8;
    }
    &.theme-4 {
      background:#00F0FF;
    }
    &.theme-5{
      background:#FF003D;
    }
    &.theme-6{
      background:#5900CC;
    }
    &.theme-7{
      background:#2A38F8;
    } 
  }

  .media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60vw;

    .rtn-ascii {
      position: absolute;
 
    }

    .rtn-ascii-2{
      width: 100%;
      
    }

    .lottie {
      position: absolute;
      width:82vw;
      margin-top:8vw;
    }

    .row {
      display: flex;
      width:100%;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
  
      .ascii { 
        height: 1.3vw;
        margin-bottom:.27vw;
        
        &.gap { 
          padding-right:10vw;
        }
    
        &.flip {
          transform: scale(-1);
          padding-left:.2vw;
        }
    
      }
    }
    
    
  }

  .title-row{
    margin-top:4.2vw;
    display: flex;
    position: relative;
    width:100%;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: row;

    .title-row-left {
      h1 {
        font-size:4.4vw;
        // font-style: italic;
        // font-weight: 100;
        font-weight: 400;
        font-family:  "Victor Narrow Regular Italic";
        letter-spacing: -.044vw;
      }
    }


    .title-row-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      h2 {
        color:#0E0E0E;
        font-feature-settings: 'ss02' on;
        font-size: 4.4vw;
        font-weight:400;
        line-height: 125%;
        letter-spacing: -.044vw;
        margin:0;
        padding:0;
        a{
          color:#0E0E0E;
          text-decoration: underline;
        }
      }
      h4 {
        margin:0;
        padding:0;
        margin-top:.6vw;
        color: #0E0E0E;
        font-feature-settings: 'ss02' on;
        font-size: 1.2vw;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.01vw;
        text-transform: uppercase;
      }
    }
  }



  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    .title-row{
      margin-top:4.2vw;
      display: flex;
      position: relative;
      width:100%;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 8.4vw);
      margin-left:4.2vw;
  
      .title-row-left {
        h1 {
          font-size:10.6vw;
          
          font-weight: 400;
          font-family:  "Victor Narrow Regular Italic";
          letter-spacing: -.1vw;
        }
      }
  
  
      .title-row-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
  
        h2 {
          display: flex;
          color:#0E0E0E;
          font-feature-settings: 'ss02' on;
          font-size: 5.333vw;
          font-weight:400;
          line-height: 125%;
          letter-spacing: -.044vw;
          margin:0;
          padding:0;
          width:100%;
          
          a{
            color:#0E0E0E;
            text-decoration: underline;
          }
        }
        h4 {
          display: flex;
          align-items: center;
          // align-self: stretch;
          justify-self: center;
          
          width:fit-content;
          margin:0;
          padding:0;
          margin-top:8.4vw;
          color: #0E0E0E;
          font-feature-settings: 'ss02' on;
          font-size: 3.2vw;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.02vw;
          text-transform: uppercase;
        }
      }
    }
  
  }


 
}


