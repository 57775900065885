.header{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  width:100%;
  padding:0;
  margin:0;
  position: relative;
  padding-bottom:.5vw;
  overflow-x:hidden;
  overflow-x:hidden;
  overflow-y: visible;
  min-height: 4vw;



&.theme-1 {
  color:#05FF00;
}

&.theme-2 {
  color:#FFFF00;
}

&.theme-3 {
  color:#2A38F8;
}

&.theme-4 {
  color:#00F0FF;
}

&.theme-5 {
  color:#FF003D;
}

&.theme-6 {
  color:#5900CC;
}

&.theme-7 {
  color:#2A38F8;
}
 

  .row {
    display: flex;
    width:100%;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    

    &.indent { 
      margin-left:4vw;
    }
    &.outdent { 
      margin-right:4vw;
    }

    .ascii { 
      height: 1.4vw;
      margin-bottom:.26vw;
      
      &.gap { 
        padding-right:2vw;
      }
  
      &.flip {
        transform: scale(-1);
        padding-left:.2vw;
      }
  
    }
  }

  @media only screen and (max-width: 1023px) {

  }

  @media only screen and (max-width: 767px) {

    margin:0;
    padding:0;
    min-height: 12vw;
    margin-bottom:2.4vw;
    height:auto;
    align-items: stretch;
    
    .row {
     
  
      &.indent { 
        margin-left:0vw;
      }
      &.outdent { 
        margin-right:0vw;
      }
  
      .ascii { 
        height: 5vw;
        margin-bottom:1vw;
        
        &.gap { 
          padding-right:16vw;
        }
    
        &.flip {
          transform: scale(-1);
          padding-left:0;
        }
    
      }
    }
   
  }


 
}


