
    .media-player-canvas {
      width:100%;
      height:auto;
      max-width:1280px;
      aspect-ratio: 1080/720;
      
      z-index: 1;

      @media only screen and (max-width: 1023px) {
    
      }
    
      @media only screen and (max-width: 767px) {
        width:110%;
        // margin-left:-10%;
      }
      
    }

    .gif-container {
      // background:black;
      width:auto;
      display: flex; 
      flex-direction: column;
      justify-content: center; 
      align-items: flex-start;
      border-radius: .7vw;
      background-color: #2A38F8;
      //border:solid 1px rgba(0, 0, 0, 0.733);
      
      position: relative;
      overflow:hidden;
      width:28.8vw;
      padding:.4vw;
      // padding-left:.5vw;
      // padding-right: .5vw;

      &.theme-1 {
        background-color: #05FF00;
      }

      &.theme-2 {
        background-color: #FF0;
      }

      &.theme-3 {
        background-color: #2A38F8;
      }

      &.theme-4 {
        background-color: #00F0FF;
      }

      &.theme-5 {
        background-color: #FF003D;
      }

      &.theme-6 {
        background-color: #5900CC;
      }

      &.theme-7 {
        background-color: #2A38F8;
      }

      .gif {
        margin-top:.4vw;
        border-radius: .7vw;
        width: 100%;
        height: 30vw;
        object-fit: cover;
      }
      .background {
        width: 100%;
        // width:98%;
        // position: absolute;
        // z-index: 0;
        // width:100%;
        // height: 100%;
        // object-fit:contain;
      }

      @media only screen and (max-width: 767px) {
        width:91.6vw;
        padding:1vw;
        border-radius: 2vw;

        .gif {
  
          margin-top:1vw;
          border-radius: 2vw;
          width: 100%;
          height: 90vw;
          object-fit: cover;
  
        }
      }

    }

    .video-container {
      text-indent: 0;
      width:36vw;
      display: flex;
    
  
      flex-direction: column;
      justify-content: center; 
      align-items: flex-start;
      border-radius: .7vw;
      background-color: #2A38F8;
      // border:solid 1px rgba(0, 0, 0, 0.733);
      
      position: relative;
      overflow:hidden;
      padding:.4vw;

      &.theme-1 {
        background-color: #05FF00;
      }

      &.theme-2 {
        background-color: #FF0;
      }

      &.theme-3 {
        background-color: #2A38F8;
      }

      &.theme-4 {
        background-color: #00F0FF;
      }

      &.theme-5 {
        background-color: #FF003D;
      }

      &.theme-6 {
        background-color: #5900CC;
      }

      &.theme-7 {
        background-color: #2A38F8;
      }

      &.shiftRight {
        left:10vw;
      }

      .react-player {
        // margin-left:1%;
        // margin-top:2vw;
        // margin-bottom:1%;
        // border:1px inset #666;
        width: 100%;
        margin:0;
        padding: 0;
        margin-top:.5vw;
        aspect-ratio: 16/9;
        border-radius: .7vw;
        overflow: hidden;
        border:none;

        div {
          margin-top:-1px;
        }
      }

      .background {
        
       
        width:75%;
        
      }
     

      @media only screen and (max-width: 767px) {
        width:91.6vw;
        padding:1vw;
        border-radius: 2vw;

        .react-player {
          border-radius: 2vw;
        }

        &.shiftRight {
          left:0vw;
        }
      }

    }

    .text-container {
      height: 100%;
      width: 100%;
      margin:0;
      padding:0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media only screen and (max-width: 767px) {
        display: none;
       }
     
      h1{
        margin:0;
        padding:0;
        font-size: 22vw;
        font-weight: 400;
        line-height: 80%;
        text-transform: uppercase;
        text-indent: 0;
        will-change: transform, opacity;
        
        mix-blend-mode: difference;
       
        &.outline {
          -webkit-text-stroke: 2px #0E0E0E; /* Webkit browsers (Safari, Chrome) */
          text-stroke: 2px #0E0E0E;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
        &.italic {
          font-family: "Victor Narrow Regular Italic";
          font-size:24vw;
          font-style: italic;
        }
      }
      
    }


    .text-container-pills {
      height: 100%;
      width: 100%;
      margin:0;
      padding:0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media only screen and (max-width: 767px) {
        height:80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
      }


      h1{
        position: absolute;
        margin:0;
        padding:0;
        margin-left:-20%;
        font-size: 4.8vw;
        font-weight: 400;
        line-height: 80%;
        text-transform: uppercase;
        text-indent: 0;
        color:#0E0E0E;
        border-radius: 5.4vw;
        padding:1.4vw;
        padding-left:2.4vw;
        padding-right: 2.4vw;
        will-change: transform, opacity;

        @media only screen and (max-width: 767px) {
          margin-left: 0;
          font-size: 6.4vw;
          padding:4.2vw;
          padding-left:8.4vw;
          padding-right: 8.4vw;
          border-radius: 8.4vw;
        }

        &.green {
          background-color:#05FF00;
        }
        &.yellow {
          background-color:#FF0;
        }
        &.blue {
          background-color:#2A38F8;
        }
        &.cyan {
          background-color:#00F0FF;
        }
        &.red{
          background-color:#FF003D;
        }
        &.purple{
          background-color:#5900CC;
        }
        &.pink{
          background-color:#DA8BFF;
        } 
        &.grey{
          background-color:#83807C;
        } 
        
      }
      
    }

   

  




 



