.section-header{
  display: flex;
  // justify-content: center;
  // align-items: center;
  height: auto;
  padding:0;
  margin:0;
  position: sticky;
  z-index: 100;
  width:calc(100% - 48px);
  margin-left:24px;
  box-sizing: border-box;
  margin-top:2.4vw;
  margin-bottom:2.4vw;
  line-height: 0;
  top:2.4vw;


  .box {
    padding:0;
    margin:0;
    box-sizing: border-box;
    width:calc(100%);
    border:1px solid #0E0E0E;
   

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 4.4vw;

    .act {
      font-family: "StabilGrotesk";
      font-size: 1.1vw;
      font-weight: 400;
      text-transform: uppercase;
      border-right:1px solid #0E0E0E;
      display: flex;
      align-items: center;
      justify-content: center;
      width:6.7vw;
      flex-shrink: 0;
      height: 100%;
    }

    .numeral {
      font-feature-settings: 'ss02' on;
      font-family: "StabilGrotesk";
      font-size: 30px;
      font-style: italic;
      font-weight: 800;
      letter-spacing: -0.3px;
      text-transform: uppercase;
      border-right:1px solid #0E0E0E;
      display: flex;
      align-items: center;
      justify-content: center;
      width:5vw;
      flex-shrink: 0;
      height: 100%;
    }

    .title {
      font-family: "Victor Narrow Regular Italic";
      font-size: 16px;
      // font-style: italic;
      // font-weight: 400;
      border-right:1px solid #0E0E0E;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width:72.5vw;
      flex-shrink: 0;
      padding-left:2.4vw;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;

      height: 100%;
      
      svg {
        width:4.2vw;
      }
    }
  }

  .box-mobile {
      display: none;
  }

  /* theme specific colors */
  &.section-1{
    background: #F4E9E1;
    
  }
  &.section-2, &.section-4{
    &.theme-1 {
      background:#2A38F8;
      .title {
        color:#05FF00;
      }
    }
    
    &.theme-2 {
      background:#FF3D00;
      .title {
        color:#FFFF00;
      }
    }
    
    &.theme-3 {
      background:#DA8BFF;
      .title{
        color:#2A38F8;
      }
    }
    
    &.theme-4 {
      background:#5900CC;
      .title {
        color:#00F0FF;
      }
      .act, .numeral, .logo {
        color:#F4E9E1;
      }
    }
    
    &.theme-5 {
      background:#FFFF00;
      .title {
        color:#FF003D;
      }
    }
    
    &.theme-6 {
      background:#05FF00;
      .title {
        color:#5900CC;
      }
    }
    
    &.theme-7 {
      background:#00F0FF;
      .title {
        color:#2A38F8;
      }
    }
  }


  &.section-3{
    &.theme-1 {
      background:#05FF00;
      .title {
        color:#2A38F8;
      }
    }
    
    &.theme-2 {
      background:#FFFF00;
      .title {
        color:#FF3D00;
      }
    }
    
    &.theme-3 {
      background:#2A38F8;
      .title{
        color:#DA8BFF;
      }
    }
    
    &.theme-4 {
      background:#00F0FF;
      .title {
        color:#5900CC;
      }
    }
    
    &.theme-5 {
      background:#FF003D;
      .title {
        color:#FFFF00;
      }
    }
    
    &.theme-6 {
      background:#5900CC;
      .title {
        color:#05FF00;
      }
    }
    
    &.theme-7 {
      background:#2A38F8;
      .title {
        color:#00F0FF;
      }
    }
  }

  &.section-5 {
    background: #83807C;
    .title {
      color:#F4E9E1;
    }
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    position: relative;
    padding-top:4.8vw;
    padding-bottom:8.4vw;
    width:calc(100%);
    margin: 0;
    box-sizing: border-box;
    

    .box {
      display: none;
    }

    .box-mobile {
      padding:0;
      margin:0;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width:calc(100% - 8.4vw);
      margin-left:4.2vw;

      border:1px solid #0E0E0E;


      .row {
        height: 10vw;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-bottom:1px solid #0E0E0E;
      }

      .act {
        font-family: "StabilGrotesk";
        font-size: 4.26vw;
        font-weight: 400;
        text-transform: uppercase;
        border-right:1px solid #0E0E0E;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        height: 100%;
      }
  
      .numeral {
        font-feature-settings: 'ss02' on;
        font-family: "StabilGrotesk";
        font-size: 4.66vw;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        border-right:1px solid #0E0E0E;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        height: 100%;
      }
  
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        height: 100%;
        
        svg {
          width:8.5vw;
        }
      }

      .title {
        font-family: "Victor Narrow Regular Italic";
        font-size: 5.33vw;
        font-style: italic;
        font-weight: 400;
        height: 10vw;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }


    }

    
  }


 
}


